// FNPVVEP-23 - INICIO
import React, { useState, useRef } from 'react'; // FNPVVEP-24 - INICIO/FIM

import Modal from '../../components/Modal';
import Form from './Form';
import Table from './Table';
import Alert from '../../components/Alert'; // FNPVVEP-24 - INICIO/FIM
import './style.scss';
import Button from './Button';
import { MSGS } from './msgs'; // FNPVVEP-24 - FIX02 - INICIO/FIM
import { ContactUs } from '../../components/ContactUs'; // FNPVVEP-135 - INICIO/FIM

export default function Entrada() {
  const [modalOpen, setModalOpen] = useState(false);
  // FNPVVEP-135 - INICIO
  const [tokenGenerated, setTokenGenerated] = useState({ show: false, msg: null }); // FNPVVEP-86 - INICIO/FIM | // FNPVVEP-86 - FIX01 - INICIO/FIM
  const [errorModal, setErrorModal] = useState({ title: null, body: null });
  // FNPVVEP-135 - FIM
  // FNPVVEP-24 - INICIO
  const [showAlert, setShowAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState('');
  const [msgAlert, setMsgAlert] = useState('');
  // FNPVVEP-24 - FIX - INICIO
  const [search, setSearch] = useState(false); // FNPVVEP-24 - FIX02 - INICIO
  const timeOutSearch = useRef(null);
  // FNPVVEP-24 - FIX - FIM
  const timeOutRef = useRef(null);
  // FNPVVEP-24 - FIM

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  // FNPVVEP-24 - INICIO
  const handleShowAlert = (obj) => {
    setShowAlert(prevState => !prevState);
    setTypeAlert(obj.type);
    setMsgAlert(obj.msg);
  };
  // FNPVVEP-24 - FIM

  // FNPVVEP-24 - FIX02 - INICIO
  const handleSearch = (time, showMsg=false) => {
    if(timeOutSearch.current) clearTimeout(timeOutSearch.current);
    timeOutSearch.current = setTimeout(() => {
      if(!!showMsg) handleShowAlert({type: 'successMsg', msg: MSGS.relSucessoMsg});
      setSearch(prevState => !prevState);    
    }, time);
  }
  // FNPVVEP-24 - FIX02 - FIM

  // FNPVVEP-135 - INICIO
  const handleErrorModalClose = () => {
    setErrorModal({ title: null, body: null });
  };

  const showErrorMessage = () => {
    setErrorModal({
        title: 'Telefone não cadastrado e/ou incorreto!',
        body: <ContactUs message='Olá, gostaria de cadastrar um telefone para receber meu token!' />,
      });
  }
  // FNPVVEP-135 - FIM

  return (
    <>
      <div className="relatorio-container">
        <section className='modalLoaderErro on'>
          <div className='content'>
              <div className="loader"></div>
          </div>
        </section>
        <div className="pagina off">
          { /* FNPVVEP-24 - INICIO */ }
          <Alert  
            type={typeAlert}
            msg={msgAlert}
            stateAlert={showAlert}
            timeOutRef={timeOutRef}
          />
          <div id='busca-local'>
            { /* //FNPVVEP-24 - FIX06 - INICIO */}
            <Button onClick={handleModalOpen} name='Novo Relatório' icon='FaPlus' /> 
            <Button onClick={() => handleSearch(0)} name='Atualizar Registros' icon='FaSync' />
            { /* //FNPVVEP-24 - FIX06 - FIM */}
          </div>
          { /* FNPVVEP-24 - FIM */ }
          {/* FNPVVEP-135 - INICIO */}
          <Table handleShowAlert={handleShowAlert} stateSearch={search} showErrorMessage={showErrorMessage} setTokenGenerated={setTokenGenerated} />
          {/* FNPVVEP-135 - FIM */}
        </div>
      </div>
      {modalOpen && (
        <Modal title="Novo Relatório" onClose={handleModalClose}>
          <Form handleShowAlert={handleShowAlert} handleModalClose={handleModalClose} handleSearch={handleSearch} setTokenGenerated={setTokenGenerated} showErrorMessage={showErrorMessage} /> {/* FNPVVEP-24 - INICIO/FIM | FNPVVEP-24 - FIX - INICIO/FIM | // FNPVVEP-86 - FIX01 - INICIO/FIM // FNPVVEP-135 - INICIO/FIM */}
        </Modal>
      )}
      {/* FNPVVEP-135 - INICIO */}
      { /* FNPVVEP-86 - INICIO | FNPVVEP-86 - FIX01 - INICIO */}
      {tokenGenerated.show && <Modal title="Token Gerado com Sucesso!" onClose={() => setTokenGenerated({ show: false, msg: null })}>
        <div className='modalToken tokenGenerated'>
          <p>{tokenGenerated.msg}</p>
        </div>
      </Modal>}
      { /* FNPVVEP-86 - FIM | FNPVVEP-86 - FIX01 - FIM */}
      {errorModal.body !== null && (
          <Modal title={errorModal.title} onClose={handleErrorModalClose}>
            {errorModal.body}
          </Modal>
        )}
        {/* FNPVVEP-135 - FIM */}
    </>
  );
}
// FNPVVEP-23 - FIM
