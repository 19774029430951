// PLV-4254 
import React, { useEffect, useState } from 'react';
import { FaSearch } from 'react-icons/fa';

import './style.scss';

import Select from './inputs/Select';
import Text from './inputs/Text';

export default function LocalSearch(props){
    const [fields, setFields] = useState({});
    const [waningMessage, setWarningMessage] = useState(""); // FNPVVEP-23 - INICIO/FIM
    // PLV 4351 - Felipe -INICIO  

    // PLV 4351 - Felipe -FINAL
    useEffect(e=>{
        setFields(props.fields);
    }, [props])

    const getInputs = (modulo) => {
        return modulo.map((input, i)=>{
            let chave = input.param + i;
            return input.type === 'select'? 
                <Select key={chave} id={chave} opcoes={input.values} nome={input.label} 
                    param={input.param} placeholder={input.placeholder}/> : 
                <Text key={chave} id={chave} nome={input.label} type={input.type} param={input.param} 
                    placeholder={input.placeholder}/>
        })
    } 

    // FNPVVEP-23 - INICIO
    const handleWarningMessage = (filterLabel) => {
        if (filterLabel === 'status') {
            setWarningMessage('Busca limitada até 100 apólices');
        }
        else {
            setWarningMessage('');
        }
    }
    // FNPVVEP-23 - FIM

    const viewInputs = (event)=>{
        document.querySelector('.modulosBusca').classList.add('on');
        [...document.querySelectorAll('.modulosBusca > .module')].forEach(e=> e.classList.remove('on'));
        document.querySelector('.modulosBusca .' + event.target.value).classList.add('on');
        handleWarningMessage(event.target.value); // FNPVVEP-23 - INICIO/FIM
    }

    // PLV 4351 - Felipe -INICIO
    const setError = (event) => {
        
        document.querySelectorAll('.modulosBusca .on .input-field, .modulosBusca .on .select-wrapper').forEach(e=>{
            let valido = e.querySelector('input, select').value !== '' && e.querySelector('input, select').value !== 'null';
            let mensagem = e.querySelector('input, select').attributes.placeholder ?
                    "Preencha no formato "+e.querySelector('input, select').attributes.placeholder.value : 
                    "Preencha o campo corretamente";

            if(!valido){
                e.classList.add('error');
                e.dataset.error = "Preencha o campo corretamente";
            } else {
                e.classList.remove('error');
                e.dataset.error = "";
            }
            
            let tamanhoCorreto = (e.querySelector('input, select').attributes.minlength ? 
                e.querySelector('input, select').value.length >= e.querySelector('input, select').attributes.minlength.value : true);
            
                if(!tamanhoCorreto){
                e.classList.add('error');
                e.dataset.error = mensagem;
            }
        })
    }

    const validateSubmit = (event)=>{
        // PLV-5554 - FIX02 - IN�CIO
        // PLV-5554 INICIO
    //    let apolice = document.querySelector('#numeroApolice0');
   
    //     if(apolice.value.length == 19){
    //         if(apolice.value.charAt(0) == '0'){
    //             apolice.value = apolice.value.slice(1);
    //         }
    //     }     
        // PLV-5554 FIM 
        // PLV-5554 - FIX02 - FIM

        event.preventDefault();
        
        let valid = [...document.querySelectorAll('.modulosBusca .on input, .modulosBusca .on select')].every(e=>{
            e.parentNode.classList.remove('error');
            return e.value !== '' && e.value !== null && e.value !== 'null' && 
                (e.attributes.minlength ? e.value.length >= e.attributes.minlength.value : true)
        });  

        if(valid){
            let params = {};
            document.querySelectorAll('.modulosBusca .on .input-field, .modulosBusca .on .select-wrapper').forEach(e=>{
                var obj = e.querySelector('input, select');
                if (obj.value !== '' && obj.value !== 'null' && obj.value !== null) {
                    params[obj.name] = obj.value;
                }
            })
            props.setSearch(params);
            setError(event);
        }else{
            setError(event);
        }
    }
    // PLV 4351 - Felipe -FINAL

    // FNPVVEP-23 - INICIO
    return(
        <>
        <form id='busca-local' onSubmit={validateSubmit}>
            <div>
                <div className="select-wrapper">
                    <select name="filter" onChange={viewInputs} id="filter-by" defaultValue={'null'}>
                        <option value="null" disabled={true} >Selecione</option>
                        {
                            Object.keys(fields).map((key, i)=>{
                                let chave = key+"_"+i;
                                return (
                                    <option value={key} key={chave} >{fields[key].label}</option>
                                )
                            })
                        }
                    </select>
                    <label htmlFor="filter-by">Filtrar por</label>
                </div>
            </div>
            <div className='modulosBusca'>
                {
                    Object.keys(fields).map((key, i)=>{
                        let modulo = fields[key];
                        let chave = key+"_"+i;
                        return (
                            <div className={key + ' module'} key={chave} >
                                { getInputs(modulo.module) }
                            </div>
                        )
                    })
                }
                <button type="submit" className="btn-primary"><FaSearch size={14} />Busca rápida</button>
            </div>
        </form>
        {!!waningMessage && <p className="waning-message">{waningMessage}</p>}
        </>
    )
    // FNPVVEP-23 - FIM
};