import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaUser } from 'react-icons/fa';

import './style.scss';
import Logo from '../../assets/porto-seguro-logo.png';

export default function Header(props){
    const location = useLocation();
    const currentPage = location.pathname;

    const [susep, setSusep] = useState(props.susep);
    const [canal, setCanal] = useState('');

    const changeActive = (event)=>{
        event.target.closest('header').querySelectorAll('a').forEach(e => e.classList.remove('active')); // FNPVVEP-23 - INICIO/FIM
        event.target.classList.add('active');
    }
    
    useEffect(() => {
        let currentButton = document.querySelector('[href="' + currentPage + '"]')
        if(currentButton) currentButton.classList.add('active')
        setSusep(props.susep);
        setCanal('Corretor')

        document.querySelectorAll('header nav a').forEach(e=> e.addEventListener('click', changeActive));
    }, [currentPage, susep, props.susep]);

    const showLogoEvent = ()=>{
        
        if(currentPage !== '/'){
            return(
                <div className="logo">
                    <Link to="/"><img src={Logo} alt="Porto Seguro"/></Link>
                </div>
            )
        }
    }

    return(
        <header>
            <div className="container">
                <input type="checkbox" name="" id="menu-toggle"/>
                <label htmlFor="menu-toggle" className="hamburguer hidden-lg">
                    <span>
                        <div></div>
                        <div></div>
                        <div></div>
                    </span>
                </label>
                
                {showLogoEvent()}
                
                <label htmlFor="menu-toggle" className="nav-shadow">
                    <span></span>
                </label>
                <nav>
                    <Link to="/contratacao">Novo seguro</Link>
                    {/* <Link to="/contas">Meus clientes</Link> */}
                    {/* FNPVVEP-23 - INICIO */}
                    <div className='dropdown'>
                        <Link to="/contratos">Meus contratos</Link>
                        <div className="dropdown-content">
                            <Link to="/contratos">Contratos</Link>
                            <Link to="/relatorios">Relatórios</Link>
                        </div>
                    </div>
                    <Link to="/contratos" className="submenu">Contratos</Link>
                    <Link to="/relatorios" className="submenu">Relatórios</Link>
                    {/* FNPVVEP-23 - FIM */}
                    <Link to="/propostas">Minhas propostas</Link>
                    <Link to="/orcamentos">Meus orçamentos</Link>
                    <Link to="/pendencias">Minhas pendências</Link> {/* II-33 - INICIO/FIM */}
                    {/* <Link to="/casos">Meus sinistros</Link> */}
                    <div className="user hidden-lg">
                        <FaUser size={30} />
                        <div className="info">
                            <strong>{susep}</strong>
                            <span>{canal}</span>
                        </div>
                    </div>
                </nav>
                
                <div className="user hidden-xs">
                    <FaUser size={30} />
                    <div className="info">
                        <strong>{susep}</strong>
                        <span>{canal}</span>
                    </div>
                </div>
            </div>
        </header>
    );
}