// FNPVVEP-23 - INICIO
import React, { useState } from 'react'; // FNPVVEP-24 - FIX02 - INICIO/FIM // FNPVVEP-86 - INICIO/FIM
import fnc from '../../../util/functions'; // FNPVVEP-24 - INICIO/FIM
import DataGrid from '../../../components/DataGrid';
import { MSGS } from '../msgs'; // FNPVVEP-24 - FIX02 - INICIO/FIM
import { FaDownload, FaChevronCircleRight, FaSpinner } from 'react-icons/fa'; // FNPVVEP-86 - INICIO/FIM
import './style.scss';
import Modal from '../../../components/Modal';

const replaceRenovacao = {SA: 'Renovacao', CA: 'Renovação'}

export default function Table({handleShowAlert, stateSearch, showErrorMessage}) { // FNPVVEP-37 - INICIO/FIM
  // FNPVVEP-86 - INICIO | FNPVVEP-86 - FIX01 - INICIO
  const [tokenValue, setTokenValue] = useState(''); // FNPVVEP-135 - INICIO/FIM
  const [showModalToken, setShowModalToken] = useState(false);
  // FNPVVEP-135 - INICIO
  const [showModalRefreshToken, setShowModalRefreshToken] = useState(false);
  const [tokenMessage, setTokenMessage] = useState('');
  // FNPVVEP-135 - FIM
  const [showSpinner, setShowSpinner] = useState(false);
  const [erroToken, setErroToken] = useState('');
  const [objRel, setObjRel] = useState({});
  // FNPVVEP-86 - FIM | FNPVVEP-86 - FIX01 - FIM

  // FNPVVEP-24 - INICIO
  const colunas = ['Ano', 'Data Início', 'Data Fim', 'Tipo', 'Status', 'Data de criação', 'Situação', 'Ação']; //FNPVVEP-24 - FIX06 - INICIO/FIM
  const qtditens = 10;

  // FNPVVEP-37 - INICIO
  const downloadCsv = async () => { // FNPVVEP-86 - INICIO/FIM
    try {
      // FNPVVEP-135 - INICIO
      const fileName = objRel.nome.replace(/[^\p{L}\p{N}]/gu, ''); // FNPVVEP-86 - INICIO/FIM
      const response = await fetch(`/api/exportcontractreport?id=${objRel.id}&filename=${fileName}&token=${tokenValue}`);
      if (!response.ok) {
        setErroToken(MSGS.erroTokenMsg)
        return;
      }
      handleShowAlert({type: 'infoMsg', msg: MSGS.infoMsg});
      // FNPVVEP-135 - FIM
      const result = await response.blob();
      const url = window.URL.createObjectURL(result);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${fileName}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url); 
      handleShowAlert({type: 'successMsg', msg: MSGS.sucessoMsg});    
      setShowModalToken(false); // FNPVVEP-135 - INICIO/FIM
    } catch (err) {
      handleShowAlert({type: 'errorMsg', msg: MSGS.erroMsg});
      console.error('Error Downloading data:', err);
    }    
  }
  // FNPVVEP-37 - FIM

  // FNPVVEP-86 - INICIO
  const handleModalToken = (action, id=0, nome='') => {
    setErroToken('');
    if(!!action) setObjRel({id, nome});
    setShowModalToken(action);
  }

  // FNPVVEP-86 - FIX01 - INICIO
  // FNPVVEP-135 - INICIO
  const handleTokenSubmit = async (e) => {
    e.preventDefault();

    if (tokenValue.trim() === '') {
      setErroToken(MSGS.requiredTokenMsg);
      return;
    }
    setShowSpinner(true);
    await downloadCsv();
    setShowSpinner(false);
  };

  const handleInputToken = (e) => {
    setTokenValue(e.target.value);
    e.target.value === '' ? setErroToken(MSGS.requiredTokenMsg) : setErroToken(''); 
  }
  // FNPVVEP-135 - FIM

  const handleForgotToken = async () => {
    handleModalToken(false);
    handleShowAlert({type: 'infoMsg', msg: MSGS.recoverTokenMsg});
    // FNPVVEP-135 - INICIO
    const susep = localStorage.getItem('susepCorretor');
    // FNPVVEP-135 - INICIO
    const response = await fetch(`/api/contractreport/refresh-token?id=${objRel.id}&susep=${susep}`);
    const result = await response.json();

    if (response.ok) {
      // FNPVVEP-135 - FIM
      setTokenMessage(result.msg);
      setShowModalRefreshToken(true);
    } else if (response.status === 422) {
      showErrorMessage();
    } else {
      handleShowAlert({ type: 'errorMsg', msg: MSGS.erroTokenMsg });
    }
    // FNPVVEP-135 - FIM
  }
  // FNPVVEP-86 - FIX01 - FIM
  // FNPVVEP-86 - FIM

  const getDataTable = async () => {
    try {
      console.log('getting Data...');
      const response = await fetch(`/api/contractreport?susep=${localStorage.getItem('susepCorretor')}`);
      const result = await response.json();
      if(result['data'].length === 0) return [];
      return result['data'].map(data => {
        return [
          data.filtroano ?? '------',
          data.filtrodatainicio ? fnc.parseDate(data.filtrodatainicio) : '------', //FNPVVEP-24 - FIX06 - INICIO/FIM
          data.filtrodatafim ? fnc.parseDate(data.filtrodatafim) : '------', //FNPVVEP-24 - FIX06 - INICIO/FIM
          data.filtrotipo.replace(/;/g, " / ").replace(replaceRenovacao.SA, replaceRenovacao.CA),
          data.filtrostatus.replace(/;/g, " / "),
          fnc.parseDate(data.datacriacao),
          data.status,
          data.status === 'Concluído' ? <button onClick={() => handleModalToken(true, data.id, data.nome)}><FaDownload size={15} /> Download</button> : '------' // FNPVVEP-37 - INICIO/FIM // FNPVVEP-86 - INICIO/FIM
        ]
      })
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  // FNPVVEP-24 - FIM

  return (
    /* // FNPVVEP-86 - INICIO */
    <>
    <DataGrid cols={colunas} search={stateSearch} getData={getDataTable} qtditens={qtditens}/> {/* FNPVVEP-24 - FIX - INICIO/FIM */}
    {showModalToken && <Modal title="Informe o Token" onClose={() => handleModalToken(false)}>
      <div className='modalToken'>
          {/* FNPVVEP-135 - INICIO */}
          <form onSubmit={handleTokenSubmit} className='sendToken'>

            <input type='text' name='valueToken' placeholder='Ex: vuyr6sBw3aq5bJt8ZVQLgF' onChange={handleInputToken} autoComplete='off'  />
            <button
              type='submit'
              aria-label="Submit Token"
              disabled={showSpinner}
            >
              {showSpinner ? <FaSpinner size={30} className="spinner" /> : <FaChevronCircleRight size={30} className='svgFaChevronCircleRight' />}
            </button>
          </form>
          {/* FNPVVEP-135 - FIM */}
        <div>
          {erroToken !== '' && <p className="error-message">{erroToken}</p>}
          {/* FNPVVEP-135 - INICIO */}
          <button
              onClick={handleForgotToken}
              style={{
                background: 'none',
                border: 'none',
                color: 'inherit',
                textDecoration: 'underline',
                cursor: 'pointer',
                paddingLeft: 0,
              }}
            >
              Esqueci meu Token
            </button>
        </div>
      </div>
    </Modal>}
    {showModalRefreshToken && <Modal title='Token Atualizado!' onClose={() => setShowModalRefreshToken(false)}>{tokenMessage}</Modal>}
    {/* FNPVVEP-135 - FIM */}
    </>
    /* // FNPVVEP-86 - FIM */
  );
}
// FNPVVEP-23 - FIM
