// FNPVVEP-135 - INICIO
import React from 'react';

export const ContactUs = ({ message = '' }) => (
  <>
    <p>Por favor entre em contato com a Central de Atendimento:</p>
    <ul className="contact">
      <li>11 3366 3377 (Grande São Paulo)</li>
      <li>0800 727 9393 (Demais localidades)</li>
      <li>
        WhatsApp:{' '}
        <a
          href={`https://wa.me/551130039303?text=${message}`}
          target="_blank"
        >
          (11) 3003 9303
        </a>
      </li>
    </ul>
  </>
);
// FNPVVEP-135 - FIM
